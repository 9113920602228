:root {
  --color-surface: white;
  --color-solid: black;
  --blue: #3431AF;
  --menu-link: #777777;
  --mate-green: #009e7f;
  --mate-red: #ff5b60;
  --mate-yellow: #ffad5e;
}

@font-face {
  font-family: 'MinionPro';
  src: url('./assets/fonts/MinionPro-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

body, button {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'MinionPro', serif; /* Usa serif come fallback */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f7f7f7; */
  background-color: #e8e4e5;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
